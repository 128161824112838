<template>
  <span>
    <slot></slot>
    <span @click.stop="">
      <el-dialog
        class="neweldialog"
        title="新建/编辑用户"
        :visible.sync="dialogVisible"
        width="600px"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @close="closeHandle"
      >
        <div class="flex flex-pc">
          <el-form
            label-position="right"
            label-width="90px"
            :model="formData"
            :rules="rules"
            ref="addForm"
          >
            <el-form-item label="用户:" prop="userName">
              <el-input
                :disabled="operateType == 'look' ? true : false"
                v-model="formData.userName"
                style="width: 300px"
                placeholder="输入姓名"
              ></el-input>
            </el-form-item>
            <el-form-item label="手机号:" prop="mobile">
              <el-input
                :disabled="operateType == 'look' ? true : false"
                v-model="formData.mobile"
                @blur="inputHandle"
                style="width: 300px"
                placeholder="输入机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="是否管理员:" prop="isAdmin">
              <el-radio-group v-model="formData.isAdmin">
                <el-radio :label="0">否</el-radio>
                <el-radio :label="1">是</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="所属机构:" prop="orgId">
              <el-tree-select
                ref="group-select-tree"
                class="group-select-tree"
                v-model="formData.orgId"
                :selectParams="selectParams"
                :treeParams="treeParams"
                style="width: 300px"
              >
              </el-tree-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select
                :disabled="operateType == 'look' ? true : false"
                v-model="formData.status"
                style="width: 300px"
              >
                <el-option label="启用" :value="1"></el-option>
                <el-option label="停用" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="账号类型:">
              <div class="dateDiv">
                <el-input
                  v-model="date"
                  disabled
                  suffix-icon="el-icon-date"
                  style="width: 300px"
                ></el-input>
                <el-date-picker
                  :disabled="operateType == 'look' ? true : false"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  v-model="dateMess"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator=""
                  style="width: 30px"
                  :picker-options="pickerOptions"
                >
                </el-date-picker>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            v-if="operateType != 'look'"
            type="primary"
            @click="sureHandel"
            :loading="loading"
            >确 定</el-button
          >
          <el-button @click="dialogVisible = false">取 消</el-button>
        </span>
      </el-dialog>
    </span>
  </span>
</template>

<script>
import "./addUser.less";
import axios from "@/utils/axios";
export default {
  name: "addUser",
  data() {
    var self = this;
    return {
      loading: false,
      dialogVisible: false,
      formData: {
        accountBeginValidDate: "",
        accountEndValidDate: "",
        accountType: 1, //1永久2临时(默认1)
        mobile: "",
        orgId: "",
        pwd: "",
        roleId: "",
        status: 1,
        userName: "",
        isAdmin: 0,
      },
      date: "永久",
      dateMess: "",
      pickerOptions: {
        onPick({ maxDate, minDate }) {
          if (maxDate && minDate) {
            let yearMin = minDate.getFullYear();
            let monthMin = minDate.getMonth() + 1;
            let dayMin = minDate.getDate();
            if (monthMin < 10) {
              monthMin = "0" + monthMin;
            }
            if (dayMin < 10) {
              dayMin = "0" + dayMin;
            }
            let resMin = yearMin + "-" + monthMin + "-" + dayMin + " 00:00:00";

            let yearMax = maxDate.getFullYear();
            let monthMax = maxDate.getMonth() + 1;
            let dayMax = maxDate.getDate();
            if (monthMax < 10) {
              monthMax = "0" + monthMax;
            }
            if (dayMax < 10) {
              dayMax = "0" + dayMax;
            }
            let resMax = yearMax + "-" + monthMax + "-" + dayMax + " 00:00:00";

            self.date = resMin + " 至 " + resMax;
            self.formData.accountType = 2;
            self.formData.accountBeginValidDate = resMin;
            self.formData.accountEndValidDate = resMax;
          }
        },
        shortcuts: [
          {
            text: "永久",
            onClick(picker) {
              picker.$emit("pick", ["", ""]);
              self.date = "永久";
              self.formData.accountType = 1;
              self.formData.accountBeginValidDate = "";
              self.formData.accountEndValidDate = "";
            },
          },
        ],
      },
      rules: {
        userName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          { validator: self.validatePhoneNumber, trigger: "blur" },
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        orgId: [
          {
            required: true,
            message: "请选择机构",
            trigger: "blur",
          },
        ],
        roleId: [
          {
            required: true,
            message: "请选择所属角色",
            trigger: "blur",
          },
        ],
      },
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: "请选择机构归属",
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        "check-strictly": true,
        "default-expand-all": true,
        "expand-on-click-node": false,
        data: [],
        props: {
          children: "childs",
          label: "orgName",
          value: "orgId",
        },
      },
      operateType: null,
      dataInfo: null
    };
  },
  mounted() {
    console.log(this.data, "this.data");
  },
  methods: {
    //初始化
    init() {
      this.dialogVisible = true;
      this.$nextTick(async () => {
        this.$refs["addForm"].resetFields();
        if (this.operateType === "edit") {
          this.formData = {
            accountBeginValidDate: this.dataInfo.accountBeginValidDate,
            accountEndValidDate: this.dataInfo.accountEndValidDate,
            accountType: this.dataInfo.accountType,
            mobile: this.dataInfo.mobile,
            orgId: this.dataInfo.orgId.toString(),
            pwd: this.dataInfo.pwd,
            roleId: this.dataInfo.roleId,
            status: this.dataInfo.status,
            userName: this.dataInfo.userName,
            id: this.dataInfo.id,
            isAdmin: this.dataInfo.isAdmin
          };
        }
        const userinfo = localStorage.getItem("currentUser");
        const orgId = JSON.parse(userinfo).orgId;
        const rs = await axios.get(`/api/org/getOrgTree/${orgId}`);
        this.treeParams.data = [rs];
        this.$nextTick(() => {
          this.$refs["group-select-tree"].treeDataUpdateFun(this.treeParams.data);
        });
      });
    },
    closeHandle() {
      this.$refs["addForm"].resetFields();
      this.dataInfo = null
      this.formData = {
        accountBeginValidDate: "",
        accountEndValidDate: "",
        accountType: 1, //1永久2临时(默认1)
        mobile: "",
        orgId: "",
        pwd: "",
        roleId: "",
        status: 1,
        userName: "",
        isAdmin: 0,
      }
    },
    inputHandle() {
      this.formData.pwd = this.formData.mobile.substring(
        this.formData.mobile.length - 6,
        this.formData.mobile.length
      );
    },
    // async clickHandel() {
    //   // this.dialogVisible = true;
    //   if (this.operateType === "edit" || this.operateType === "look") {
    //     this.formData = {
    //       accountBeginValidDate: this.dataInfo.accountBeginValidDate,
    //       accountEndValidDate: this.dataInfo.accountEndValidDate,
    //       accountType: this.dataInfo.accountType,
    //       mobile: this.dataInfo.mobile,
    //       orgId: this.dataInfo.orgId.toString(),
    //       pwd: this.dataInfo.pwd,
    //       roleId: this.dataInfo.roleId,
    //       status: this.dataInfo.status,
    //       userName: this.dataInfo.userName,
    //       id: this.dataInfo.id,
    //     };
    //     if (this.dataInfo.accountType == 2) {
    //       this.date =
    //         this.dataInfo.accountBeginValidDate +
    //         " 至 " +
    //         this.dataInfo.accountEndValidDate;
    //     }
    //   }
    //   const userinfo = localStorage.getItem("currentUser");
    //   const orgId = JSON.parse(userinfo).orgId;
    //   const rs = await axios.get(`/api/org/getOrgTree/${orgId}`);
    //   this.treeParams.data = [rs];
    //   this.$nextTick(() => {
    //     this.$refs["group-select-tree"].treeDataUpdateFun(this.treeParams.data);
    //   });
    // },
    sureHandel() {
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.operateType === "edit") {
            axios
              .post("/api/userInfo/edit", this.formData)
              .then(() => {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.dialogVisible = false;
                    this.$emit("getDataR");
                  },
                });
                this.loading = false;
                this.$refs.addForm.resetFields();
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          } else {
            axios
              .post("/api/userInfo/create", this.formData)
              .then(() => {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 500,
                  onClose: () => {
                    this.dialogVisible = false;
                    this.$emit("getDataR");
                  },
                });
                this.loading = false;
                this.$refs.addForm.resetFields();
              })
              .catch((err) => {
                this.loading = false;
                this.$message.error(err);
              });
          }
        }
      });
    },
    validatePhoneNumber(rule, value, callback) {
      if (/^1[3|4|5|7|8][0-9]{9}$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入合法的手机号"));
      }
    },
  },
};
</script>

<style>
</style>
