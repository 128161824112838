<template>
  <el-dialog
    :visible.sync="visible"
    :title="title"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width="25%"
    @close='closeHandle'
  >
    <div class="handle-content">
      <el-form
        ref="dataForm"
        :model="dataForm"
        :rules="rules"
        class="demo-form-inline"
      >
        <el-form-item :label-width="'90px'" prop="">
          <el-tree
            :data="routerMap"
            v-loading="loading"
            :check-strictly="checkStrictly"
            show-checkbox
            node-key="id"
            :props="defaultProps"
            check-on-click-node
            :default-expand-all="true"
            :default-checked-keys="checked"
            ref="tree"
          >
          </el-tree>
        </el-form-item>
      </el-form>
    </div>
    <template slot="footer">
      <el-button
        type="primary"
        :loading="loading"
        @click="dataFormSubmitHandle()"
        >确认</el-button
      >
      <el-button @click="visible = false">取消</el-button>
    </template>
  </el-dialog>
</template>
<script>
import axios from "@/utils/axios";
export default {
  data() {
    return {
      title: null,
      loading: false,
      checkStrictly: false,
      dataForm: {
        userId: null,
        organId: null,
      },
      visible: false,
      routerMap: [],
      defaultProps: {
        id: 'id',
        children: 'children',
        label: (item) => {
          if (item) {
            return item.name
          }
        }
      },
      checked: []
    };
  },
  computed: {
    rules() {
      return {
        action: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        suggest: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
        nextPerson: [
          { required: true, message: "必填项不能为空", trigger: "change" },
        ],
      };
    },
  },
  methods: {
    //初始化
    init() {
      this.visible = true;
      this.$nextTick(() => {
        this.getAuthTree();
        this.$refs["dataForm"].resetFields();
      });
    },
    closeHandle() {
      this.$refs.tree.setCheckedKeys([]);
      this.checked = []
      this.routerMap = []
    },
    async getAuthTree() {
      this.loading = true
      await axios
        .post(`/api/userPermission/getPermissionTree`, {currentId: localStorage.getItem('userId'), userId: this.dataForm.userId})
        .then((res) => {
          if (res) {
            let list = res.children
            let checked = []
            list.forEach(element => {
              if(element.flag === 1) {
                // element.disabled = true
                checked.push(element.id)
              }
              if(element.children && element.children.length > 0) {
                element.children.forEach(item => {
                  if(item.flag === 1) {
                    // item.disabled = true
                    checked.push(item.id)
                  }
                })
              }
            })
            this.routerMap = list
            this.loading = false
            this.getCurrPermission()
          }
        })
        .catch((err) => {
          this.loading = false;
          return this.$message.error(err);
        });
    },
    //获取当前用户拥有的权限
    getCurrPermission() {
      axios
        .post(`/api/userPermission/permissionList`, this.dataForm)
        .then((res) => {
          if (res) {
            this.checked = res
          }
        })
        .catch((err) => {
          this.loading = false;
          return this.$message.error(err);
        });
    },
    dataFormSubmitHandle() {
      this.$refs["dataForm"].validate((valid) => {
        if (!valid) {
          return false;
        }
        this.loading = true;
        let ids = this.$refs.tree.getCheckedKeys(false)
        ids.push('0')
        let dataForm = {
          userId: this.dataForm.userId,
          permissionIds: ids.join(',')
        }
        axios
          .post(`/api/userPermission/savePermission`, dataForm)
          .then((res) => {
            if (res) {
              this.$message({
                message: "操作成功",
                type: "success",
                duration: 500,
                onClose: () => {
                  this.visible = false;
                  this.$emit("refreshDataList");
                },
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            return this.$message.error(err);
          });
      });
    },
  },
};
</script>