<template>
  <div class="user-management-container flex flex-col">
    <div class="flex flex-ac flex-pj">
      <el-form :inline="true">
        <el-form-item label="用户:">
          <el-input
            v-model="search.name"
            style="width: 220px"
            placeholder="输入姓名/或者手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属机构:">
          <el-tree-select
            ref="group-select-tree"
            class="group-select-tree"
            v-model="search.orgId"
            :selectParams="selectParams"
            :treeParams="treeParams"
            style="width: 300px"
          >
          </el-tree-select>
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="search.state" style="width: 220px">
            <el-option label="全部" value=""></el-option>
            <el-option label="启用" value="1"></el-option>
            <el-option label="停用" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" @click.stop="getUserList()">搜索</el-button>
        <el-button plain @click.stop="getDataR()">重置</el-button>
      </div>
    </div>
    <div class="mt10">
      <addUser :roleList="roleList" :getDataR="getDataR" operateType="add">
        <el-button type="primary" @click="addHandle">新建</el-button>
      </addUser>
    </div>
		<div class="user-table-content">
			<el-table
				style="width: 100%"
				height="100%"
				highlight-current-row
				stripe
				:data="list"
				key="MenuList"
			>
				<el-table-column prop="userName" label="姓名" width="200">
				</el-table-column>
				<el-table-column prop="mobile" label="手机号" width="200">
				</el-table-column>
				<el-table-column prop="createTime" label="注册/添加时间" width="200">
				</el-table-column>
				<el-table-column prop="status" label="状态" width="100">
					<template slot-scope="scope" width="200">
						<span style="color: #008000" v-if="scope.row.status == 1">启用</span>
						<span style="color: #ff0000" v-else>停用</span>
					</template>
				</el-table-column>
				<el-table-column prop="orgText" label="归属机构" width="100">
				</el-table-column>
				<el-table-column prop="accountType" label="账号类型" width="100">
					<template slot-scope="scope">
						<span style="color: #008000" v-if="scope.row.accountType == 1"
							>永久</span
						>
						<span style="color: #ff0000" v-else>临时</span>
					</template>
				</el-table-column>
				<el-table-column prop="roleText" label="是否管理员">
          <template slot-scope="scope" width="200">
						<span style="color: #008000" v-if="scope.row.isAdmin == 1">是</span>
						<span style="color: #ff0000" v-else>否</span>
					</template>
				</el-table-column>
				<!-- <el-table-column prop="lastLoginTime" label="最后登录时间" min-width="150">
				</el-table-column> -->
				<el-table-column label="操作" width="600">
					<template slot-scope="scope">
						<el-button
							type="text"
							v-if="scope.row.status === 1 && scope.row.id !== userId"
							@click="setStateHandle(scope.row.id, 'close')"
							>停用</el-button
						>
						<el-button
							type="text"
							v-if="scope.row.status === 2 && scope.row.id !== userId"
							@click="setStateHandle(scope.row.id, 'open')"
							>启用</el-button
						>
						<el-button type="text" v-if="scope.row.id !== 1" @click="deleteUser(scope.row.id)"
							>删除</el-button
						>
						<el-button type="text" @click="resretPwd(scope.row)"
							>初始化密码</el-button
						>
						<el-button type="text" @click="editHandle(scope.row)">编辑</el-button>
						<el-button type="text" @click="authHandle(scope.row)">授权</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>	
    
    <div class="page-container">
      <div class="table-page-container">
        <el-pagination
          background
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <AddWin v-if="visiable" ref="addModel" />
    <ChangeState v-if="stateVisiable" ref="state" @getDataR="getDataR" />
    <addUser v-if="updateVisiable" ref="update" @getDataR="getDataR" />
  </div>
</template>

<script>
import "./Index.less";
import ChangeState from "./components/ChangeState";
import addUser from "./components/addUser";
import axios from "@/utils/axios";
import AddWin from "./components/AddWin";
export default {
  name: "UserManagement",
  components: {
    ChangeState,
    addUser,
    AddWin,
  },
  data() {
    return {
      visiable: false,
      stateVisiable: false,
      updateVisiable: false,
      page: 1,
      pageSize: 20,
      total: 0,
      search: {
        name: "",
        orgId: "",
        state: "",
      },
      list: [],
      roleList: [],
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: "请选择机构归属",
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        "check-strictly": true,
        "default-expand-all": true,
        "expand-on-click-node": false,
        data: [],
        props: {
          children: "childs",
          label: "orgName",
          value: "orgId",
        },
      },
      organId: null,
    };
  },
  computed: {
    userId() {
      const userinfo = localStorage.getItem("currentUser");
      return JSON.parse(userinfo).id;
    },
  },
  mounted() {
    this.organId = localStorage.getItem("organId");
    this.search.orgId = localStorage.getItem("organId");
    this.getUserList();
    this.getOrgTreeData();
  },
  methods: {
    setStateHandle(id, state) {
      this.stateVisiable = true;
      this.$nextTick(() => {
        this.$refs.state.init();
        this.$refs.state.title = "用户授权";
        this.$refs.state.state = state;
        this.$refs.state.userId = id;
      });
    },
    editHandle(row) {
      this.updateVisiable = true;
      this.$nextTick(() => {
        this.$refs.update.init();
        this.$refs.update.title = "用户修改";
        this.$refs.update.operateType = "edit";
        this.$refs.update.dataInfo = row;
      });
    },
    addHandle() {
      this.updateVisiable = true;
      this.$nextTick(() => {
        this.$refs.update.init();
      });
    },
    //
    async getOrgTreeData() {
      const userinfo = localStorage.getItem("currentUser");
      const orgId = JSON.parse(userinfo).orgId;
      const rs = await axios.get(`/api/org/getOrgTree/${Number(orgId)}`);
      this.treeParams.data = [rs];
      this.$nextTick(() => {
        this.$refs["group-select-tree"].treeDataUpdateFun(this.treeParams.data);
      });
    },
    authHandle(item) {
      this.visiable = true;
      this.$nextTick(() => {
        this.$refs.addModel.init();
        this.$refs.addModel.title = "用户授权";
        this.$refs.addModel.dataForm.userId = item.id;
        this.$refs.addModel.dataForm.organId = item.orgId;
      });
    },
    /**
     * 拼接get参数
     */
    serialize(obj) {
      let str = [];
      for (let p in obj) {
        if (obj[p]) {
          str.push(p + "=" + obj[p]);
        }
      }
      return str.join("&");
    },
    /**
     * 判断输入的是否全是数字
     */
    getIsnum(val) {
      let isnum = /^\d+$/.test(val);
      return isnum;
    },
    /**
     * 重置
     */
    getDataR() {
      this.getUserList();
    },
    getUserList() {
      let nameOrPhone = this.getIsnum(this.search.name);
      let param = {
        id: "",
        mobile: nameOrPhone ? this.search.name : "",
        orgId: this.search.orgId,
        page: this.page,
        status: this.search.state,
        userName: !nameOrPhone ? this.search.name : "",
        pageSize: this.pageSize,
      };
      let params = this.serialize(param);
      axios
        .get(
          "/api/userInfo/pageList/" +
            this.page +
            "/" +
            this.pageSize +
            "?" +
            params
        )
        .then((rs) => {
          this.list = rs.list;
          this.total = rs.total;
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
    //重置密码
    resretPwd(row) {
      this.$confirm("确认此用户密码, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post(`/api/userInfo/init`, { id: row.id })
          .then(() => {
            this.$message.success("重置成功");
            this.getUserList();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    /**
     * 删除
     */
    deleteUser(id) {
      this.$confirm("确认删除此用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        axios
          .post(`/api/userInfo/remove/${id}`)
          .then(() => {
            this.$message.success("删除成功");
            this.getUserList();
          })
          .catch((err) => {
            this.$message.error(err);
          });
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getUserList();
    },
  },
};
</script>

<style>
</style>
